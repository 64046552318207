.hide {
  display: none;
}

.card {
  :global(.ant-card-body) {
    padding: 0;
  }
}

.controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  padding: 0 30px;
  background-color: #FAFAFA;
  .nagtive {
    color: #000000A6;
  }
}

.detail {
  min-height: 300px;
  padding: 37px 33px;
  .total {
    margin-bottom: 50px;
    > span {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 70px;
      > span:first-child {
        flex: 0 32px;
        line-height: 32px;
        font-size: 18px;
        font-weight: 500;
        color: #000000D9;
      }
      > span:last-child {
        flex: 43px;
        line-height: 43px;
        font-size: 36px;
        font-weight: 800;
      }
    }
  }
}

.light-blue {
  color: #73A0FA;
}

.orange {
  color: #FF9B67;
}

.yellow {
  color: #F7B634;
}

.purple {
  color: #AD7EEC;
}
