body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.form {
  .ant-form > .ant-form-item {
    position: relative;
    padding-left: 150px;

    > .ant-form-item-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
    }

    > .ant-form-item-control-wrapper {
      width: 100%;
    }
  }

  .form-item-children,
  .ant-form-item-children {
    display: inline-block;
    width: 100%;
    > * {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.card {
  margin-bottom: 10px;
  .ant-card-body {
    padding: 0 24px;
    position: relative;
  }

  .disabled{
    height: 652px;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.6);
      top: 0;
      left: 0;
    }
    &::before {
      content: '场景需要开启后才能编辑';
      text-align: center;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      z-index: 100;
      position: absolute;
      padding-top: 240px;
      background-color: #fff;
      background-image: url(./assets/empty.png);
      background-size: 40%;
      background-repeat: no-repeat;
      background-position: 50% 30%;
      width: 480px;
      height: 320px;
      transform: translate(-50%, -50%);
      top: 30%;
      left: 50%;
    }

  }
}

.indent{
  text-indent: 2em;
  display: inline-block;
}

